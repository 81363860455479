.Home {
  /* background: #06070b; */
  color: white;
  height: 90vh;
  margin-top: 10vh;
  position: relative;
}
.Square {
  position: absolute;
  background: #de6199;
  width: 53rem;
  height: 18rem;
  left: 50%;
  margin-left: -27rem;
  top: 14rem;
}
img.Lascano {
  position: absolute;
  left: 50%;
  height: 38rem;
  top: 4rem;
  margin-left: -20.4rem;
}
img.SquareOne {
  position: absolute;
  left: 63.7%;
  width: 8.5rem;
  height: auto;
  top: 46%;
}
img.SquareTwo {
  position: absolute;
  left: 23%;
  top: 29%;
  width: 8rem;
}
img.SquareThree {
  position: absolute;
  left: 33%;
  width: 8.7rem;
  top: 12%;
}
img.SquareFour {
  position: absolute;
  left: 35%;
  width: 5.2rem;
  top: 41%;
}
img.SquareFive {
  position: absolute;
  width: 8.5rem;
  top: 60%;
  left: 43%;
}
img.SquareSix {
  position: absolute;
  width: 14rem;
  right: 37.5%;
  top: 16%;
}
img.warningMessage {
  position: absolute;
  max-width: 46rem;
  right: 37%;
  top: 22%;
}
.Home img.Logo {
  position: absolute;
  bottom: 5vw;
  max-width: 200px;
  right: 2rem;
}

.Home .warning{
  font-family: 'vcr_osd_monoregular', sans-serif;
  position: absolute;
  bottom: 45%;
  right: 18%;
  color: red;
  text-align: left;
  font-weight: 400;
  font-size: 1.4rem;
  max-width: 125px;
  line-height: 100%;
  cursor: pointer;
}
.Home .warning .circle{
  height: 25px;
  width: 25px;
  border: 1px solid red;
  border-radius: 50%;
  display: block;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}
.Home .lascanoArea{
  position: absolute;
  width: 35%;
  height: 70%;
  left: 31%;
}
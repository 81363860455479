html {
  font-size: 62.5%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Fonts */

@font-face {
  font-family: 'vcr_osd_monoregular';
  src: url('./fonts/vcr_osd_mono_1.001-webfont.woff2') format('woff2'),
    url('./fonts/vcr_osd_mono_1.001-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'gt_america_monothin';
  src: url('./fonts/gt-america-mono-thin-webfont.woff2') format('woff2'),
    url('./fonts/gt-america-mono-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'gt_america_monoregular';
  src: url('./fonts/gt-america-mono-regular-webfont.woff2') format('woff2'),
    url('./fonts/gt-america-mono-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'gt_america_expanded_light';
  src: url('./fonts/gt-america-expanded-light.woff2') format('woff2'),
    url('./fonts/gt-america-expanded-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DOS';
  src: url('./fonts/perfect_dos_vga_437_win-webfont.woff2') format('woff2'),
       url('./fonts/perfect_dos_vga_437_win-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
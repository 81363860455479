header{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
/* Header */
.header-top{
  display: flex;
  font-size: 1rem;
  color: white;
  background: #0000FF;
  justify-content: space-between;
  font-family: 'DOS',sans-serif;
}
.header-top .left-side{
  display: flex;
  width: 27rem;
  justify-content: space-between;
}
.header-top .center-side{
  margin-right: 35%;
}
.header-mid{
  display: flex;
  font-size: 1rem;
  font-family: 'DOS',sans-serif;
  position: relative;
  height: 1.5rem;
  color:white;
}
.header-mid div{
  display: flex;
  margin-left: 3rem;
  padding-left: .2rem;
  justify-content: space-between;
  width: 23.5rem;
  padding-right: 8rem;
  background: #0000FF;
  z-index: 2;
}
.header-mid:before{
  content:'';
  width: 100%;
  height: .2rem;
  background-color: white;
  position: absolute;
  top: .18rem
}
.header-mid:after{
  content:'';
  width: 100%;
  height: .2rem;
  background-color: white;
  position: absolute;
  top: .6rem;
}
.Header p{
  margin: 0;
}
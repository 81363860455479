.Brains {
  overflow: hidden;
  height: 90vh;
  position: relative;
  margin-top: 10vh;
}
.Brains p {
  font-size: 7rem;
  transition: 14s all linear;
  padding: 0 2rem;
  color: white;
  text-align: left;
  font-family:"gt_america_expanded_light",sans-serif;
  padding-left:6%;
  padding-right:6%;
}
.Background {
  background: url('../../media/Brains/bg2.png');
  position: absolute;
  width: 100%;
  height: 100vh;
  right: 0;
  top: 0;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  transition: 3s all;
  background-attachment: fixed;
}
.Button {
  height: 5.5rem;
  width: 14.5rem;
  background: black;
  position: absolute;
  color: white;
  border: 2px solid white;
  display: flex;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'vcr_osd_monoregular', sans-serif;
}
.Button:hover {
  background: white;
  color: black;
}
.Click1 {
  left: 14.5vw;
  top: 19vh;
}
.Click2 {
  left: 14.5vw;
  top: 43vh;
}
.Click3 {
  right: 11vw;
  bottom: 41vh;
}
.Click4 {
  left: 45.7vw;
  top: 61vh;
}
.gif1 {
  position: absolute;
  background-image: url('../../media/Brains/gif1.gif');
  width: 39vh;
  height: 25vh;
  top: 8vh;
  left: 14vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: right;
  border: 2px dashed white;
}
.gif1 span {
  cursor: pointer;
  color: white;
  margin-right: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
}
.gif2 {
  position: absolute;
  background-image: url('../../media/Brains/gif2.gif');
  width: 39vh;
  height: 25vh;
  top: 33vh;
  left: 14vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: right;
  border: 2px dashed white;
}
.gif2 span {
  cursor: pointer;
  color: white;
  margin-right: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
}
.ficha {
  position: absolute;
  background-image: url('../../media/Brains/ficha.png');
  width: 80vw;
  height: 78%;
  top: 0;
  left: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: -40vw;
  display: flex;
  justify-content: right;
}
.ficha-title span {
  cursor: pointer;
  color: white;
  margin-right: 0.5rem;
  padding-top: 1.3rem;
  font-size: 3rem;
}
.ficha-title{
  position: absolute;
  top: 0;
  color: white;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid white;
  box-sizing: border-box;
  font-family: 'gt_america_monoregular',sans-serif;
}
.ficha-title h1{
  font-size: 2rem;
  padding: 1rem;
  padding-bottom: 0;
  margin-bottom: .5rem;
}

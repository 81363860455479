
.img1{
    background-image:url('../../../media/Data/endgame/1.png')
}
.img2{
    background-image:url('../../../media/Data/endgame/2.png')
}
.img3{
    background-image:url('../../../media/Data/endgame/3.png')
}
.img4{
    background-image:url('../../../media/Data/endgame/4.png')
}
.img5{
    background-image:url('../../../media/Data/endgame/5.png')
}
.img6{
    background-image:url('../../../media/Data/endgame/6.png')
}
.img7{
    background-image:url('../../../media/Data/endgame/7.png')
}
.img8{
    background-image:url('../../../media/Data/endgame/8.png')
}
.img9{
    background-image:url('../../../media/Data/endgame/9.png')
}
.img10{
    background-image:url('../../../media/Data/endgame/10.png')
}
.img11{
    background-image:url('../../../media/Data/endgame/11.png')
}
.img12{
    background-image:url('../../../media/Data/endgame/12.png')
}
.img13{
    background-image:url('../../../media/Data/endgame/13.png')
}
.img14{
    background-image:url('../../../media/Data/endgame/14.png')
}
.img15{
    background-image:url('../../../media/Data/endgame/15.png')
}
.img16{
    background-image:url('../../../media/Data/endgame/16.png')
}
.img17{
    background-image:url('../../../media/Data/endgame/17.png')
}
.img18{
    background-image:url('../../../media/Data/endgame/18.png')
}
.img19{
    background-image:url('../../../media/Data/endgame/19.png')
}
.img20{
    background-image:url('../../../media/Data/endgame/20.png')
}
.img21{
    background-image:url('../../../media/Data/endgame/21.png')
}
.img22{
    background-image:url('../../../media/Data/endgame/22.png')
}
.img23{
    background-image:url('../../../media/Data/endgame/23.png')
}
.img24{
    background-image:url('../../../media/Data/endgame/24.png')
}
.img25{
    background-image:url('../../../media/Data/endgame/25.png')
}
.img26{
    background-image:url('../../../media/Data/endgame/26.png')
}
.img27{
    background-image:url('../../../media/Data/endgame/27.png')
}
.img28{
    background-image:url('../../../media/Data/endgame/28.png')
}
.img29{
    background-image:url('../../../media/Data/endgame/29.png')
}
.img30{
    background-image:url('../../../media/Data/endgame/30.png')
}
.img31{
    background-image:url('../../../media/Data/endgame/31.png')
}
.img32{
    background-image:url('../../../media/Data/endgame/32.png')
}
.img33{
    background-image:url('../../../media/Data/endgame/33.png')
}
.img34{
    background-image:url('../../../media/Data/endgame/34.png')
}
.img35{
    background-image:url('../../../media/Data/endgame/35.png')
}
.img36{
    background-image:url('../../../media/Data/endgame/36.png')
}
.img37{
    background-image:url('../../../media/Data/endgame/37.png')
}
.img38{
    background-image:url('../../../media/Data/endgame/38.png')
}
.img39{
    background-image:url('../../../media/Data/endgame/39.png')
}
.img40{
    background-image:url('../../../media/Data/endgame/40.png')
}
.img41{
    background-image:url('../../../media/Data/endgame/41.png')
}
.img42{
    background-image:url('../../../media/Data/endgame/42.png')
}
.img43{
    background-image:url('../../../media/Data/endgame/43.png')
}
.img44{
    background-image:url('../../../media/Data/endgame/44.png')
}
.img45{
    background-image:url('../../../media/Data/endgame/45.png')
}
.img46{
    background-image:url('../../../media/Data/endgame/46.png')
}
.img47{
    background-image:url('../../../media/Data/endgame/47.png')
}
.img48{
    background-image:url('../../../media/Data/endgame/48.png')
}
.img49{
    background-image:url('../../../media/Data/endgame/49.png')
}
.img50{
    background-image:url('../../../media/Data/endgame/50.png')
}
.img51{
    background-image:url('../../../media/Data/endgame/51.png')
}
.img52{
    background-image:url('../../../media/Data/endgame/52.png')
}
.img53{
    background-image:url('../../../media/Data/endgame/53.png')
}
.img54{
    background-image:url('../../../media/Data/endgame/54.png')
}
.img55{
    background-image:url('../../../media/Data/endgame/55.png')
}
.img56{
    background-image:url('../../../media/Data/endgame/56.png')
}
.img57{
    background-image:url('../../../media/Data/endgame/57.png')
}
.img58{
    background-image:url('../../../media/Data/endgame/58.png')
}
.img59{
    background-image:url('../../../media/Data/endgame/59.png')
}
.img60{
    background-image:url('../../../media/Data/endgame/60.png')
}
.img61{
    background-image:url('../../../media/Data/endgame/61.png')
}
.img62{
    background-image:url('../../../media/Data/endgame/62.png')
}
.img63{
    background-image:url('../../../media/Data/endgame/63.png')
}
.img64{
    background-image:url('../../../media/Data/endgame/64.png')
}
.img65{
    background-image:url('../../../media/Data/endgame/65.png')
}
.img66{
    background-image:url('../../../media/Data/endgame/66.png')
}
.img67{
    background-image:url('../../../media/Data/endgame/67.png')
}
.img68{
    background-image:url('../../../media/Data/endgame/68.png')
}
.img69{
    background-image:url('../../../media/Data/endgame/69.png')
}
.img70{
    background-image:url('../../../media/Data/endgame/70.png')
}
.img71{
    background-image:url('../../../media/Data/endgame/71.png')
}
.img72{
    background-image:url('../../../media/Data/endgame/72.png')
}
.img73{
    background-image:url('../../../media/Data/endgame/73.png')
}
.img74{
    background-image:url('../../../media/Data/endgame/74.png')
}
.img75{
    background-image:url('../../../media/Data/endgame/75.png')
}
.img76{
    background-image:url('../../../media/Data/endgame/76.png')
}
.img77{
    background-image:url('../../../media/Data/endgame/77.png')
}
.img78{
    background-image:url('../../../media/Data/endgame/78.png')
}
.img79{
    background-image:url('../../../media/Data/endgame/79.png')
}
.img80{
    background-image:url('../../../media/Data/endgame/80.png')
}
.img81{
    background-image:url('../../../media/Data/endgame/81.png')
}
.img82{
    background-image:url('../../../media/Data/endgame/82.png')
}
.img83{
    background-image:url('../../../media/Data/endgame/83.png')
}
.img84{
    background-image:url('../../../media/Data/endgame/84.png')
}
.img85{
    background-image:url('../../../media/Data/endgame/85.png')
}
.img86{
    background-image:url('../../../media/Data/endgame/86.png')
}
.img87{
    background-image:url('../../../media/Data/endgame/87.png')
}
.img88{
    background-image:url('../../../media/Data/endgame/88.png')
}
.img89{
    background-image:url('../../../media/Data/endgame/89.png')
}
.img90{
    background-image:url('../../../media/Data/endgame/90.png')
}
.img91{
    background-image:url('../../../media/Data/endgame/91.png')
}
.img92{
    background-image:url('../../../media/Data/endgame/92.png')
}
.img93{
    background-image:url('../../../media/Data/endgame/93.png')
}
.img94{
    background-image:url('../../../media/Data/endgame/94.png')
}
.img95{
    background-image:url('../../../media/Data/endgame/95.png')
}
.img96{
    background-image:url('../../../media/Data/endgame/96.png')
}
.img97{
    background-image:url('../../../media/Data/endgame/97.png')
}
.img98{
    background-image:url('../../../media/Data/endgame/98.png')
}
.img99{
    background-image:url('../../../media/Data/endgame/99.png')
}
.img100{
    background-image:url('../../../media/Data/endgame/100.png')
}
.img101{
    background-image:url('../../../media/Data/endgame/101.png')
}
.img102{
    background-image:url('../../../media/Data/endgame/102.png')
}
.img103{
    background-image:url('../../../media/Data/endgame/102.png')
}
.EndgameContainer{
    position: absolute;
    width: 80%;
    height: 70%;
    left: 10%;
    top: 13%;
    background-size:60%;
    background-color:black;
    background-repeat:no-repeat;
    background-position:center;
    z-index:50;
    border: 1px solid white;
}
.Reproductor {
  overflow: hidden;
  height: 90vh;
  position: relative;
  padding-top: 10vh;
  display: flex;
  background: white;
}
.Reproductor .Icons {
  z-index: 2000;
  width: 80px;
  position: absolute;
}
.Reproductor.ampliado {
  background: url("../../media/Reproductor/hackedbg.jpg");
}
.Reproductor.ampliado .critica p,
.Reproductor.ampliado h2 {
  color: white;
}
.Reproductor.ampliado h2 {
  padding-right: 3rem;
}
.movieContainer {
  position: relative;
  padding-bottom: 34%; /* 16:9 */
  padding-top: 25;
  height: 0;
  width: 60%;
  margin-left: 6vw;
}
.movieContainer.hacked {
  animation: horizontalShuffle 0.05s linear infinite;
}
.movieContainer.ampliada {
  width: 80%;
  margin-left: 3vw;
}
.movie {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.criticsContainer {
  font-family: "gt_america_monoregular", sans-serif;
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 100%;
  width: 25%;
  margin-left: 5%;
  margin-right: 2%;
}
.firstMarquee,
.secondMarquee {
  height: 100%;
  position: absolute;
  -moz-transform: translateY(110%);
  -webkit-transform: translateY(110%);
  transform: translateY(110%);
  -moz-animation: criticsTransition 20s linear infinite;
  -webkit-animation: criticsTransition 20s linear infinite;
  animation: criticsTransition 20s linear infinite;
}
.firstMarquee.hacked,
.secondMarquee.hacked {
  -moz-animation: criticsTransition 2s linear infinite;
  -webkit-animation: criticsTransition 2s linear infinite;
  animation: criticsTransition 2s linear infinite;
}
.secondMarquee {
  transform: translateY(200%);
  animation-delay: 10s;
}
.secondMarquee.hacked {
  animation-delay: 1s;
}
.firstMarquee.ampliada,
.secondMarquee.ampliada {
  -moz-animation: criticsTransition 15s linear infinite;
  -webkit-animation: criticsTransition 15s linear infinite;
  animation: criticsTransition 15s linear infinite;
}
.secondMarquee.ampliada {
  animation-delay: 7.5s;
}

.criticsContainer div {
  text-align: center;
  /* margin-left: 10%; */
  margin: 3rem 0 3rem 5%;
}
.criticsContainer h2 {
  font-family: "vcr_osd_monoregular", sans-serif;
  font-size: 1.6rem;
}
.critica p:first-child {
  font-weight: bold;
  padding: 0;
  margin: 0;
}
.critica {
  margin-bottom: 2rem;
  width: 80%;
}
.criticaImage {
  max-width: 100%;
}
.critica p:last-child {
  color: #767676;
  padding: 0;
  margin: 0;
}
.hackButton {
  font-family: "DOS", sans-serif;
  position: absolute;
  color: white;
  background: red;
  padding: 1.5rem 2rem;
  bottom: 16vh;
  left: 6vw;
  font-weight: bold;
  cursor: pointer;
  animation: infiniteButton 1400ms infinite;
  animation-delay: 16800ms;
}
.hackButton.hide {
  display: none;
}
@keyframes infiniteButton {
  from {
    background: red;
    transform: scale(1, 1);
  }
  50% {
    background: white;
    transform: scale(1.2, 1.2);
    color: black;
  }
  to {
    background: red;
    transform: scale(1, 1);
  }
}
@-moz-keyframes criticsTransition {
  0% {
    -moz-transform: translateY(110%);
  }
  100% {
    -moz-transform: translateY(-110%);
  }
}
@-webkit-keyframes criticsTransition {
  0% {
    -webkit-transform: translateY(110%);
  }
  100% {
    -webkit-transform: translateY(-110%);
  }
}
@keyframes criticsTransition {
  0% {
    -moz-transform: translateY(110%);
    -webkit-transform: translateY(110%);
    transform: translateY(110%);
  }
  100% {
    -moz-transform: translateY(-110%);
    -webkit-transform: translateY(-110%);
    transform: translateY(-110%);
  }
}
@keyframes horizontalShuffle {
  0% {
    width: 30%;
    margin-left: 6vw;
    margin-top: 3vw;
  }
  100% {
    width: 75%;
    margin-left: 3vw;
    margin-top: 0;
  }
}
